import { SPECIAL_STATES } from './constants';

/**
 * Creates range.
 * @param {number} x
 * @param {number} x2
 * @param {number} y
 * @param {string} [color]
 * @param {*} [state]
 * @param {boolean} [isInactive]
 * @returns {Object} Range
 */
const createRange = (x, x2, y, color, state, isInactive) => {
  const newRange = {
    x, x2, y, color
  };
  if (isInactive) {
    newRange.isInactive = isInactive;
    newRange.partialFill = {
      amount: 1,
      fill: 'transparent'
    };
    newRange.color = 'transparent';
  } else if (typeof state !== 'undefined') {
    newRange.state = state;
    const stateColor = SPECIAL_STATES[state].color;
    if (stateColor) {
      newRange.color = stateColor;
    }
  }

  return newRange;
};

export default createRange;
