import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../../ModalWindow';
import { DELETE_FILES_MODAL_ID } from '../../constants';
import { PrimaryButton, CancelButton } from '../../../../components/UIKit';
import { getModalsDeleteFilesModalDataIdsSelector } from '../../../../redux-store/selectors/modals';

import { deleteFiles } from '../../store/slice';
import { closeModalWindow } from '../../../ModalWindow/slice';

import i18n from '../../../../i18n';

import './index.scss';

const DeleteFilesModal = () => {
  const dispatch = useDispatch();

  const ids = useSelector(getModalsDeleteFilesModalDataIdsSelector);
  const handleOnClose = () => dispatch(closeModalWindow({ modalID: DELETE_FILES_MODAL_ID }));
  const handleDelete = () => dispatch(deleteFiles({ filesIds: ids }));

  return (
    <ModalWindow modalID={DELETE_FILES_MODAL_ID}>
      <div className="modal-header limit-size-header">
        <h5 className="modal-title download-title">
          {i18n.t('deleteFiles')}
        </h5>
      </div>
      <div className="modal-body">
        <p className="message">
          {i18n.t('deleteFilesConfirmation')}
        </p>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <PrimaryButton onClickHandler={handleDelete} type="delete" customButtonClass="delete-button" />
      </div>
    </ModalWindow>
  );
};

export default DeleteFilesModal;
