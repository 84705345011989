import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ForgotPassForm } from './components';
import { forgotPasswordSend } from './slice';
import {
  getForgotPasswordSendSuccessfullSelector,
  getForgotPasswordVerifyEmailSelector,
  getForgotPasswordIsLoadingSelector,
  getForgotPasswordCounterSelector,
  getForgotPasswordEmailSelector
} from '../../redux-store/selectors/forgotPassword';

import i18n, { transformLanguageForAPI } from '../../i18n';

import '../../App.scss';

/**
 * @module ForgotPass
 */

/**
 * @description renders page to restore password
 * @memberof module:ForgotPass
 * @extends Component
 */
const ForgotPass = () => {
  const dispatch = useDispatch();

  const email = useSelector(getForgotPasswordEmailSelector);
  const counter = useSelector(getForgotPasswordCounterSelector);
  const isLoading = useSelector(getForgotPasswordIsLoadingSelector);
  const verifyEmail = useSelector(getForgotPasswordVerifyEmailSelector);
  const sendSuccessfull = useSelector(getForgotPasswordSendSuccessfullSelector);

  const onSubmit = (values) => dispatch(forgotPasswordSend({
    counter,
    fields: {
      ...values,
      language: transformLanguageForAPI(i18n.language)
    }
  }));

  const onReSubmit = () => dispatch(forgotPasswordSend({
    counter,
    fields: {
      email,
      language: transformLanguageForAPI(i18n.language)
    }
  }));

  return (
    <ForgotPassForm
      onSubmit={onSubmit}
      onReSubmit={onReSubmit}
      email={email}
      verifyEmail={verifyEmail}
      sendSuccessfull={sendSuccessfull}
      counter={counter}
      isLoading={isLoading}
    />
  );
};

export default ForgotPass;
