import React from 'react';
import PropTypes from 'prop-types';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';

import { PaginationList } from './components';
import i18n from '../../i18n';

/**
 * Renders pagination list under table and implements functionality
 * @memberof module:ReactTable
 * @param  {object} props - Pagination properties.
 * @param  {number} props.pagesAmount - Amount of pages.
 * @param  {number} props.currentPage - Current page.
 * @param  {number} props.total - Total records in table.
 * @param  {function} props.goToPageNum - Redirects to some page with records.
 * @param  {function} props.changePageSize - Changes amount of records on page.
 */
const Pagination = (props) => {
  const {
    total = undefined,
    pageSize,
    currentPage,
    goToPageNum,
    className,
    pagesAmount,
    changePageSize,
    savePaginationChanges = undefined
  } = props;

  if (!total) return null;

  const changeSizeAndSave = (size) => {
    changePageSize(+size);
    if (savePaginationChanges) savePaginationChanges('limit', +size);
  };

  const goToAndSave = (pageNum) => {
    goToPageNum(pageNum);
    if (savePaginationChanges) savePaginationChanges('start', (pageNum - 1) * pageSize);
  };

  const selectedFrom = (pageSize * (currentPage - 1)) + 1;
  const selectedTo = (pageSize * currentPage <= total) ? pageSize * currentPage : total;

  return (
    <div className={`m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded ${className}`}>
      <div className="m-datatable__pager m-datatable--paging-loaded clearfix">
        <PaginationList
          currentPage={currentPage}
          goToAndSave={goToAndSave}
          pagesAmount={pagesAmount}
        />
        <FormControl className="m-datatable__pager-info">
          <NativeSelect
            onChange={(e) => changeSizeAndSave(e.target.value)}
            name="age"
            className="m-datatable__pager-size"
            classes={{
              select: 'nativeSelectRoot nativeSelect nativeSelectMenu',
              icon: 'nativeSelectMenuIcon'
            }}
            value={pageSize}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </NativeSelect>
          <span className="m-datatable__pager-detail records">
            {`${i18n.t('displaying')} ${selectedFrom} - ${selectedTo} ${i18n.t('of')} ${total} ${i18n.t('records')}`}
          </span>
        </FormControl>
      </div>
    </div>
  );
};
Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  pagesAmount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  total: PropTypes.number,
  className: PropTypes.string,
  goToPageNum: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  savePaginationChanges: PropTypes.func
};

export default Pagination;
