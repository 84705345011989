import React from 'react';
import PropTypes from 'prop-types';
import { goBack } from 'react-router-redux';
import { useDispatch } from 'react-redux';

import { CancelButton } from '../../../../components/UIKit';
import i18n from '../../../../i18n';

import '../../../../App.scss';

const NavProfileSettings = (props) => {
  const {
    myself,
    submitting,
    roleDependencies,
    handleSubmit,
    pristine,
    userRoleName
  } = props;
  const dispatch = useDispatch();

  return (
    <div className="nav-settings">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-auto mr-sm-auto">
            <h6 className="set-prof-head">
              {i18n.t('settingsProfile')}
              {!myself && (
                <>
                  :&nbsp;
                  {i18n.t(userRoleName)}
                </>
              )}
            </h6>
          </div>
          {!roleDependencies.disabled && (
            <div className="col-auto">
              <button
                disabled={pristine || submitting}
                onClick={handleSubmit}
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-save-chan"
                type="button"
              >
                {i18n.t('saveChanges')}
              </button>
            </div>
          )}
          <div className="col-auto cont-for-canc-btn">
            <CancelButton onClickHandler={() => dispatch(goBack())} customButtonClass="emp-set-btn-canc" />
          </div>
        </div>
      </div>
    </div>
  );
};

NavProfileSettings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  userRoleName: PropTypes.string,
  myself: PropTypes.bool,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  roleDependencies: PropTypes.instanceOf(Object).isRequired
};

export default NavProfileSettings;
