import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { cloneDeep } from 'lodash';
import { SignUpForm2 } from './components';
import { signUpVerify, signUp2Send } from './slice';
import {
  getSignUp2ErrorMessageSelector,
  getSignUp2InitialValuesSelector,
  getSignUp2StopMessageSelector,
  getSignUp2VerifiedSelector
} from '../../redux-store/selectors/signUp2';
import { parsePhoneNumber } from '../validation';

import { useRedirectToStore } from '../../hooks';

import i18n, { transformLanguageForAPI, changeLanguageTo } from '../../i18n';

import '../../App.scss';

/**
 * @module SignUp2
 */
/**
 * @description renders Sign Up page(second step)
 * @memberof module:SignUp2
 * @extends Component
 */
const SignUp2 = (props) => {
  const { match } = props;
  const dispatch = useDispatch();

  const { verifyCode } = match?.params || {};

  const verified = useSelector(getSignUp2VerifiedSelector);
  const stopMessage = useSelector(getSignUp2StopMessageSelector);
  const errorMessage = useSelector(getSignUp2ErrorMessageSelector);
  const initialValues = useSelector(getSignUp2InitialValuesSelector);

  useRedirectToStore();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('lang') ? urlParams.get('lang') : i18n.language;

    changeLanguageTo(language);
    dispatch(signUpVerify({ verifyCode, language: transformLanguageForAPI(language) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Sends request to register company account
   */
  const onSubmit = (values) => {
    const copyValues = cloneDeep(values);

    if (values?.phone) {
      copyValues.phone = parsePhoneNumber(values?.phone);
    }

    if (values?.billingAddress?.phoneNumber) {
      copyValues.billingAddress.phoneNumber = parsePhoneNumber(values?.billingAddress?.phoneNumber);
    }

    dispatch(signUp2Send({
      fields: {
        ...copyValues,
        language: transformLanguageForAPI(i18n.language)
      }
    }));
  };

  return (
    <SignUpForm2
      onSubmit={onSubmit}
      verified={verified}
      initialValues={initialValues}
      errorMessage={errorMessage}
      stopMessage={stopMessage}
      enableReinitialize
    />
  );
};

SignUp2.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired
};

export default SignUp2;
