import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalWindow } from './slice';

import { getModalsSelector } from '../../redux-store/selectors/modals';

import '../../App.scss';
import './index.scss';

/**
 * @module ModalWindow
 */
/**
 * Renders modal window
 * @memberof module:ModalWindow
 */
const ModalWindow = (props) => {
  const { customOnClose = undefined, modalID, children, customClass = '', isModalOver = false } = props;
  const dispatch = useDispatch();

  const modals = useSelector(getModalsSelector);

  const modal = modals?.[modalID];

  const onClickHandler = (event) => {
    if (['modalContainer', `myModalWindow opened ${modalID}`].includes(event.target.className)) {
      if (customOnClose) {
        customOnClose();
      } else {
        dispatch(closeModalWindow({ modalID }));
      }
    }
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (customOnClose) {
        customOnClose();
      } else {
        dispatch(closeModalWindow({ modalID }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={modal?.opened ? `myModalWindow opened ${modalID}` : `myModalWindow ${modalID}`}
      style={{ zIndex: isModalOver ? '1001' : '1000' }}
      onClick={onClickHandler}
    >
      <div className="modalContainer">
        <div className={`modal-dialog ${customClass}`}>
          <div className="modal-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

ModalWindow.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  customOnClose: PropTypes.func,
  modalID: PropTypes.string.isRequired,
  isModalOver: PropTypes.bool,
  customClass: PropTypes.string
};

export default ModalWindow;
