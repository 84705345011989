import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';

import { SignUpLayout } from './components';
import { openModalWindow, closeModalWindow, QR_CODE_SCANNER_MODAL_ID } from '../ModalWindow/slice';
import { QRCodeScannerModalWindow } from '../../modals';
import { signUpSend, resendToEmail, sendEmailChange, changeEmailAction } from './slice';
import {
  getSignUpChangeEmailSelector,
  getSignUpResendEmailSelector,
  getSignUpSuccessfullSelector,
  getSignUpEmailSelector
} from '../../redux-store/selectors/signUp';

import i18n, { transformLanguageForAPI } from '../../i18n';

import '../../App.scss';
import './index.scss';

const SIGN_UP_USER_FORM = 'signUpUserForm';

/**
 * renders Sign Up page
 * @extends Component
 */
const SignUp = (props) => {
  const {
    // TODO: remove changeEmailAction/resendToEmail funcs from connect()
    changeEmailAction: changeEmailActionFromProps,
    resendToEmail: resendToEmailfromProps,
    match
  } = props;
  const dispatch = useDispatch();

  const emailFromProps = useSelector(getSignUpEmailSelector);
  const resendEmail = useSelector(getSignUpResendEmailSelector);
  const changeEmail = useSelector(getSignUpChangeEmailSelector);
  const formStates = useSelector(getFormValues(SIGN_UP_USER_FORM));
  const sendSuccessfull = useSelector(getSignUpSuccessfullSelector);

  const resendToEmail_ = resendToEmailfromProps.bind(null, {
    email: emailFromProps,
    language: transformLanguageForAPI(i18n.language)
  });

  const [sm_id, setSm_id] = useState('');
  const [email, setEmail] = useState('');

  /**
   * @description Sends request to register company
   */
  const onSubmitCompany = (values) => dispatch(signUpSend({ type: 'company', language: transformLanguageForAPI(i18n.language), fields: values }));

  /**
   * @description Sends request to register user
   */
  const onSubmitUser = (values) => dispatch(signUpSend({ type: 'user', language: transformLanguageForAPI(i18n.language), fields: values }));

  /**
   * @description Sends request to change email
   */
  const onSubmitChangeEmail = (values) => dispatch(sendEmailChange({ language: transformLanguageForAPI(i18n.language), values }));

  /**
   * @description opens window to scan QR code
   */
  const scanQR = (result) => {
    if (formStates.sm_id) {
      setSm_id(formStates.sm_id);
    }
    if (formStates.email) {
      setEmail(formStates.email);
    }
    if (typeof result !== 'string') return;
    setSm_id(result);
    dispatch(closeModalWindow({ modalID: QR_CODE_SCANNER_MODAL_ID }));
  };

  const openQRScanner = () => dispatch(openModalWindow({ modalID: QR_CODE_SCANNER_MODAL_ID, data: null }));

  useEffect(() => {
    setSm_id(match.params.sm_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <QRCodeScannerModalWindow scanQR={scanQR} />
      <SignUpLayout
        onSubmitCompany={onSubmitCompany}
        onSubmitUser={onSubmitUser}
        onSubmitChangeEmail={onSubmitChangeEmail}
        sentEmails={{ email: emailFromProps }}
        sendSuccessfull={sendSuccessfull}
        resendEmail={resendEmail} // boolean
        actions={{ resendToEmail: resendToEmail_, changeEmailAction: changeEmailActionFromProps }}
        changeEmail={changeEmail}
        openQRScanner={openQRScanner}
        sm_id={sm_id}
        email={email}
        i18n={i18n}
      />
    </>
  );
};

SignUp.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  resendToEmail: PropTypes.func.isRequired,
  changeEmailAction: PropTypes.func.isRequired
};

export default connect(null, {
  resendToEmail,
  changeEmailAction
})(SignUp);
