import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Logo } from '../../components';
import { LanguageSelector } from '../../components/UIKit';

import { changeEmailVerify } from './slice';
import {
  getVerifyChangeEmailVerifiedSelector,
  getVerifyChangeEmailErrorMessageSelector
} from '../../redux-store/selectors/verifyChangeEmail';
import i18n from '../../i18n';

import '../../App.scss';

/**
 * Page for confirming changed email.
 * @module VerifyChangeEmail
 */
/**
 * Renders main component of page for confirming changed email.
 * @memberof module:VerifyChangeEmail
 */
const VerifyChangeEmail = (props) => {
  const { match } = props;
  const dispatch = useDispatch();

  const verified = useSelector(getVerifyChangeEmailVerifiedSelector);
  const errorMessage = useSelector(getVerifyChangeEmailErrorMessageSelector);

  useEffect(() => {
    const { verifyCode } = match?.params || {};
    dispatch(changeEmailVerify({ verifyCode }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1">
      <div className="container">
        <div className="m-login__logo row justify-content-center">
          <div className="col-lg-12 col-auto">
            <Logo />
          </div>
          <div className="col-auto">
            <LanguageSelector className="topLanguageSelector" />
          </div>
        </div>
      </div>
      <div className="m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center">
        <div className="m-login__container">
          <div className="row align-items-center">
            <div className="m-login__signin col-lg-12">
              <div className="loadingText">
                {!verified && i18n.t('linkVerifmess')}
                {(verified && errorMessage) ? errorMessage : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VerifyChangeEmail.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired
};

export default VerifyChangeEmail;
