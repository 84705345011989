import view from './view.json';

const replace = {
  // C
  changeSMID: view.changeENVID,

  // R
  reqSMID: view.envisionID,

  // S
  SMID: view.ENVID,
  SMIDTolltip: view.ENVIDTolltip
};

export default replace;
