import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import ResetPassForm from './ResetPassForm';
import { resetPassSend } from './actions';
import i18n, { transformLanguageForAPI, changeLanguageTo } from '../../i18n';
import { useRedirectToStore } from '../../hooks';

import '../../App.scss';

/**
 * @memberof module:ResetPass
 * @description renders Reset Password form
 * @extends Component
 */
const ResetPass = (props) => {
  const { match } = props;
  const dispatch = useDispatch();

  const { verifyCode } = match?.params || {};

  /** Sends request to reset password
   * @param  {object} values - form values to reset password
  */
  const onSubmit = (values) => dispatch(resetPassSend({ language: transformLanguageForAPI(i18n.language), fields: values }));

  useRedirectToStore();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('lang') || i18n.language;

    changeLanguageTo(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ((verifyCode) ? (
    <ResetPassForm
      onSubmit={onSubmit}
      initialValues={{ code: verifyCode }}
    />
  ) : null);
};

ResetPass.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired
};

export default ResetPass;
