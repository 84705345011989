export const themeMui = {
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#8088F0'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#8088F0',
      dark: '#102B4A'
    },
    secondary: {
      main: '#FFF',
      dark: '#8F939B',
      lowTariff: '#1BBC9B',
      highTariff: '#F53920',
      stepColor: '#C6C8D2',
      stepSubtitleColor: '#959BA2',
      blockBackgroundLight: '#102B4A',
      darkBg: '#04182E'
    }
  },
  typography: {
    h2: {
      fontSize: 20,
      fontWeight: 600
    },
    h3: {
      fontSize: 18,
      fontWeight: 600
    },
    h4: {
      fontSize: 16,
      fontWeight: 400
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
    fontFamily: ['Suisse Intl, sans-serif'].join(',')
  }
};
